
import { defineComponent } from "vue";
import { themeName } from "@/core/helpers/documentation";
import CodeHighlighter from "@/view/content/highlighters/CodeHighlighter.vue";
import Info from "@/view/content/sections/Info.vue";

export default defineComponent({
  name: "theme-color",
  components: {
    CodeHighlighter,
    Info
  },
  setup() {
    return {
      themeName
    };
  }
});
