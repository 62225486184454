
import { defineComponent } from "vue";
import CodeHighlighter from "@/view/content/highlighters/CodeHighlighter.vue";

export default defineComponent({
  name: "width-and-height",
  components: {
    CodeHighlighter
  },
  setup() {
    const widthAndHeightValues = [100, 150, 200, 250];

    return {
      widthAndHeightValues
    };
  }
});
