
import { defineComponent, ref } from "vue";
import CodeHighlighter from "@/view/content/highlighters/CodeHighlighter.vue";

export default defineComponent({
  name: "z-index",
  components: {
    CodeHighlighter
  },
  setup() {
    const zIndexValues = ref({
      n1: -1,
      n2: -2,
      "0": 0,
      "1": 1,
      "2": 2,
      "3": 3
    });

    return {
      zIndexValues
    };
  }
});
